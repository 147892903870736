import React, { useState } from 'react'
import { navigate } from 'gatsby'
// import ReactGoogleMapLoader from 'react-google-maps-loader'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon
} from 'react-google-maps'

import styles from './style.module.scss'

import * as primary from '../../styles/primary-pallete'

import cn from 'classnames'

const Tour =
    typeof window === 'undefined' ? () => {} : require('reactour').default

function parseJson(string) {
    try {
        return JSON.parse(string)
    } catch (e) {
        return null
    }
}

function getPosition(area) {
    if (area.acf.area_center) {
        let arr = area.acf.area_center.replace(/ /g, '').split(',')

        return (
            arr && {
                lat: parseFloat(arr[0]),
                lng: parseFloat(arr[1])
            }
        )
    }

    let arr = parseJson(area.acf.google_map_polygon)

    arr = arr[0][0]

    return (
        arr && {
            lat: arr[1],
            lng: arr[0]
        }
    )
}
const MyMap = withScriptjs(
    withGoogleMap(({ data, active }) => {
        return (
            <GoogleMap
                center={getPosition(active)}
                zoom={13}
                mapTypeId="satellite"
            >
                {data.allWordpressWpAreas.edges.map(({ node: area }) => {
                    const path = parseJson(area.acf.google_map_polygon)

                    if (!path) {
                        return null
                    }

                    if (active.wordpress_id !== area.wordpress_id) return null

                    return (
                        <Polygon
                            key={area.name}
                            visible
                            path={path[0].map(arr => {
                                const coords = {
                                    lat: arr[1],
                                    lng: arr[0]
                                }
                                return coords
                            })}
                            options={{
                                strokeOpacity: 0.8,
                                strokeWeight: 3,
                                fillColor: primary.base,
                                fillOpacity: 0.35
                            }}
                            onClick={() => navigate('/area/' + area.slug)}
                        />
                    )
                })}
            </GoogleMap>
        )
    })
)

export default ({ data }) => {
    const [active, setActive] = useState(
        data.allWordpressWpAreas.edges.find(({ node: area }) => {
            return parseJson(area.acf.google_map_polygon)
        }).node
    )

    const [tourIsOpen, setTourIsOpen] = useState(true)

    return (
        <div className={styles.container}>
            <h1>Dubai Areas Map</h1>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-2">
                        <div className={styles.areaSidebar}>
                            <h2>Areas</h2>
                            {data.allWordpressWpAreas.edges.map(
                                ({ node: area }, i) => {
                                    return (
                                        parseJson(
                                            area.acf.google_map_polygon
                                        ) && (
                                            <div
                                                className={cn(
                                                    styles.areaName,
                                                    {
                                                        [styles.active]:
                                                            active.wordpress_id ===
                                                            area.wordpress_id
                                                    },
                                                    {
                                                        firstArea: i === 3
                                                    }
                                                )}
                                                key={area.name}
                                                onClick={() => setActive(area)}
                                            >
                                                {area.name}
                                            </div>
                                        )
                                    )
                                }
                            )}
                        </div>
                    </div>
                    <div className={cn('col-xs-10', styles.mapColumn)}>
                        {typeof window !== 'undefined' && (
                            <Tour
                                steps={steps}
                                isOpen={tourIsOpen}
                                onRequestClose={() => setTourIsOpen(false)}
                            />
                        )}
                        <div
                            className={cn('fakePolygon', styles.fakePolygon, {
                                [styles.fakePolygonOpen]: tourIsOpen
                            })}
                        />
                        <MyMap
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCEuv0PXLvDLyV007oeZUD_1j-SpoTazns"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                                <div
                                    style={{
                                        height: `100vh`,
                                        position: 'relative'
                                    }}
                                />
                            }
                            mapElement={<div style={{ height: `100%` }} />}
                            data={data}
                            active={active}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const steps = [
    {
        selector: '.firstArea',
        content: 'Choose an area from left side'
    },
    {
        selector: '.fakePolygon',
        content: "Click to see it's projects"
    }
]
