import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import SeoSearch from '../components/seo-search'

import AreasMap from '../components/areas-map'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[{ title: 'Home', link: '/' }, { title: 'Areas' }]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <AreasMap data={data} />
                    </div>
                </div>
            </div>

            <SeoSearch data={data} />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    acf {
                        google_map_polygon
                        area_center
                    }
                }
            }
        }

        allWordpressWpSeoSearch {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
        allWordpressPage(filter: { slug: { eq: "dubai-area-map" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
